import bg from '../assets/img/bg.png';
import eyes from '../assets/img/eyes.png';
import image_01 from '../assets/img/image_01.png';
import image_03 from '../assets/img/image_03.png';

export const images = [
    bg,
    eyes,
    image_01,
    image_03,
];
